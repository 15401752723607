import { html, text } from '../util';
import { setComplete } from '../completion';

export default (args) => {
  setComplete(8);
  if (args.includes('--help')) {
    return text('you\'ll see :)');
  } else {
    return html(atob('PGRpdiBzdHlsZT0iZm9udC1zaXplOiB4LWxhcmdlOyBkaXNwbGF5OiBmbGV4OyB3aWR0aDogNWVtOyBoZWlnaHQ6IDJlbTsgcGFkZGluZzogMHB4OyBtYXJnaW46IDBweDsgZmxleC13cmFwOiB3cmFwIj48ZGl2IHN0eWxlPSJ3aWR0aDogMWVtOyBoZWlnaHQ6IDFlbTsgYmFja2dyb3VuZC1jb2xvcjogcmdiKDIyOCwyMjcsMTg2KSI+PC9kaXY+PGRpdiBzdHlsZT0id2lkdGg6IDFlbTsgaGVpZ2h0OiAxZW07IGJhY2tncm91bmQtY29sb3I6IHJnYigxODQsMTU0LDU5KSI+PC9kaXY+PGRpdiBzdHlsZT0id2lkdGg6IDFlbTsgaGVpZ2h0OiAxZW07IGJhY2tncm91bmQtY29sb3I6IHJnYigxNzYsMTcwLDY0KSI+PC9kaXY+PGRpdiBzdHlsZT0id2lkdGg6IDFlbTsgaGVpZ2h0OiAxZW07IGJhY2tncm91bmQtY29sb3I6IHJnYigxNzEsMTYxLDM0KSI+PC9kaXY+PGRpdiBzdHlsZT0id2lkdGg6IDFlbTsgaGVpZ2h0OiAxZW07IGJhY2tncm91bmQtY29sb3I6IHJnYigzNCw0MCwxNzQpIj48L2Rpdj48ZGl2IHN0eWxlPSJ3aWR0aDogMWVtOyBoZWlnaHQ6IDFlbTsgYmFja2dyb3VuZC1jb2xvcjogcmdiKDY2LDQzLDE3NykiPjwvZGl2PjxkaXYgc3R5bGU9IndpZHRoOiAxZW07IGhlaWdodDogMWVtOyBiYWNrZ3JvdW5kLWNvbG9yOiByZ2IoMTAsNTEsNDgpIj48L2Rpdj48ZGl2IHN0eWxlPSJ3aWR0aDogMWVtOyBoZWlnaHQ6IDFlbTsgYmFja2dyb3VuZC1jb2xvcjogcmdiKDIzNCw3NSwxODYpIj48L2Rpdj48ZGl2IHN0eWxlPSJ3aWR0aDogMWVtOyBoZWlnaHQ6IDFlbTsgYmFja2dyb3VuZC1jb2xvcjogcmdiKDE4Niw1MSwxODcpIj48L2Rpdj48ZGl2IHN0eWxlPSJ3aWR0aDogMWVtOyBoZWlnaHQ6IDFlbTsgYmFja2dyb3VuZC1jb2xvcjogcmdiKDE2MCwxMDUsMTA1KSI+PC9kaXY+PC9kaXY+'));
  }
};