import { commandShortcut } from '.';
import { getCommandCompletion, getFilesSeen, getCompletionAmount, completionsAmount, fileCount } from '../completion';
import { html, text } from '../util';

export default (args) => {
  if (args.includes('--help')) {
    return html(`
progress: returns your completion progress, as in how many secrets you have discovered.<br>
<b class="black">looking for more details? use ${commandShortcut('progress --detailed')}!</b><br>
<b class="black">looking for a hint? use ${commandShortcut('progress --hint')}!</b>`);
  } else if (args.includes('--detailed')) {
    return html(`
you have discovered:<br><br>
<b>${getCommandCompletion()} / ${completionsAmount}</b> command secrets<br>
<b>${getFilesSeen()} / ${fileCount}</b> files<br>
<br>
<b class="black">progress tracking does not work retroactively! if you've been here before this system was added,<br>chances are a lot of your progress hasn't been saved</b>`);
  } else if (args.includes('--hint')) {
    if (getCompletionAmount() >= 1) {
      return text('nope, no hints here!');
    } else {
      const hints = [
        `you can see hidden files with <b>${commandShortcut('ls -a')}</b>!`,
        'a lot more commands accept arguments like <b>--help</b> than you\'d think. try it on <b>neofetch</b> or <b>sudo</b>!',
        `you may think you're at the root of the file system, but try doing <b>${commandShortcut('cd ..')}</b> and see where it takes you!`,
        'if you\'re getting tired of manually typing in commands when navigating the filesystem, try clicking on the files in <b>ls</b>! it autocompletes the command for you.',
        `some arguments like <b>-v</b> can be used multiple times on a single command, like <b>${commandShortcut('help --help --help')}</b>. try it!`
      ];
      return html(hints[Math.floor(Math.random() * hints.length)]);
    }
  } else {
    let amt = getCompletionAmount();
    return html(`you have discovered <b class="${amt >= 1 ? 'rainbow' : ''}">${(amt * 100).toFixed(1)}%</b> of the secrets on this page!`);
  }
};