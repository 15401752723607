import { setComplete } from '../completion';
import { chunk, html } from '../util';

export default (args) => {
  setComplete(4);
  let split = chunk(args.join(' '), 39);
  if (split.length === 0) split = [''];
  return html(`

<pre>
 ${'_'.repeat(split[0].length + 2)}
${split.map((s, i) => {
    if (split.length === 1) {
      return '< ' + s + ' >';
    } else if (i === 0) {
      return '/ ' + s + ' '.repeat(split[0].length - s.length) + ' \\';
    } else if (i === split.length - 1) {
      return '\\ ' + s + ' '.repeat(split[0].length - s.length) + ' /';
    } else {
      return '| ' + s + ' '.repeat(split[0].length - s.length) + ' |';
    }
  }).join('\n')}
 ${'-'.repeat(split[0].length + 2)}
   \\   ^__^
    \\  (oo)\\_______
       (__)\\       )\\/\\
           ||----w |
           ||     ||
</pre>
`);
};