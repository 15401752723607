const LOADING_HTML = '<br><b class="black" id="webring-inner">loading...</b>';
let webringHTML = null;

export function initWebring() {
  document.querySelector('#webring').innerHTML = LOADING_HTML;

  let webring = document.querySelector('#webring-inner');
  if (webring) {
    let data = fetch('https://lavender.software/webring/data.json');
    data.then(res => res.json()).then(json => {
      let index = json.findIndex(l => l.id === 'oatmealine');
      let idxRight = (index + 1) % json.length;
      let idxLeft = (index - 1) % json.length;
      let left = json[idxLeft];
      let right = json[idxRight];
      webring.innerHTML = `<a href="${left.url}" rel="prev noreferrer external">&lt; ${left.name}</a> / part of the <a rel="external noreferrer" href="https://lavender.software/">lavender.software</a> webring / <a href="${right.url}" rel="next noreferrer external">${right.name} &gt;</a>`;
      webringHTML = document.querySelector('#webring').innerHTML;
    });
  }
}

export function getWebring() {
  if (webringHTML)
    return webringHTML;

  const elem = document.querySelector('#webring');

  if (!elem) {
    return LOADING_HTML;
  } else {
    return elem.innerHTML;
  }
}