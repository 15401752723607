import { getFromPath, setDir } from '../fs';
import { text } from '../util';
import { setError } from '.';
import { triggerThreePeaksSequence } from '../threepeaks';

export default (args) => {
  let path = args[0] || '.';
  if (path.endsWith('/') && path !== '/') path = path.slice(0, -1);
  let [type, res] = getFromPath(path);
  if (type === 'file') {
    setError(true);
    return text(`cd: ${path}: not a directory`);
  } else if (type === 'none') {
    setError(true);
    return text(`cd: no such file or directory: ${path}`);
  } else {
    if (res.includes('threepeaks')) {
      triggerThreePeaksSequence();
      return text('');
    }

    setDir(res);
    return;
  }
};