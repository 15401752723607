import cat from './cat';
import catexplosion from './catexplosion';
import cd from './cd';
import clear from './clear';
import cowsay from './cowsay';
import echo from './echo';
import eval_ from './eval';
import exit from './exit';
import help from './help';
import keydebug from './keydebug';
import ls from './ls';
import neofetch from './neofetch';
import oneko from './oneko';
import progress from './progress';
import secret from './secret';
import sex from './sex';
import sl from './sl';
import sudo from './sudo';
import version from './version';

export default {
  '': () => {},
  'cat': cat,
  'catexplosion': catexplosion,
  'cd': cd,
  'clear': clear,
  'clr': clear,
  'cowsay': cowsay,
  'echo': echo,
  'eval': eval_,
  'exit': exit,
  'help': help,
  'keydebug': keydebug,
  'ls': ls,
  'dir': ls,
  'neofetch': neofetch,
  'oneko': oneko,
  'progress': progress,
  'secret': secret,
  'sex': sex,
  'sl': sl,
  'sudo': sudo,
  'doas': sudo,
  'version': version,
};