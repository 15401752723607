import { text } from '../util';
import { commandShortcut } from '.';

let version;

document.addEventListener('DOMContentLoaded', () => {
  fetch('/version.txt').then(res => res.text()).then(v => {
    if (window.localStorage.getItem('lastVersion')) {
      let currentVersion = Number(v);
      let lastVersion = Number(window.localStorage.getItem('lastVersion') || -1);

      if (currentVersion > lastVersion) {
        document.querySelector('#tip').innerHTML = `new content has been added since you've last visited this page!\nrun ${commandShortcut('progress --detailed')} for more details`;
      }
    }

    window.localStorage.setItem('lastVersion', v);
    version = Number(v);
  });
});

export default () => {
  return text(`running qsh ${(version || version === 0) ? 'v' + version : '(unknown version?!)'}`);
};