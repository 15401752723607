import { setComplete } from '../completion';
import { text } from '../util';
import { setError, executeCommand } from '.';

export default (args) => {
  if (args.length === 0 || args[0] === '--help') {
    if (args.includes('--help')) {
      setComplete(3);
      return text('what are you looking for?');
    } else {
      setError(true);
      return text('sudo: missing arguments');
    }
  } else {
    setComplete(2);
    return executeCommand(args.join(' '));
  }
};