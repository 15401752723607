import { createOneko } from '../oneko';
import { setComplete } from '../completion';
import { text } from '../util';

export default () => {
  setComplete(9);
  if (document.querySelector('#oneko')) {
    document.querySelector('#oneko').remove();
    clearInterval(window.onekoInterval);
    return;
  } else {
    createOneko();
    return text('meow!');
  }
};