import { getFilesInFS } from './fs';

export const completionsAmount = 13;
export const fileCount = getFilesInFS();

export function setComplete(id) {
  let completion = window.localStorage.getItem('completion');
  if (!completion) {
    completion = Array(completionsAmount).fill(false);
  } else {
    completion = JSON.parse(completion);
  }

  completion[id] = true;

  window.localStorage.setItem('completion', JSON.stringify(completion));
}

export function markFileAsSeen(file) {
  let seen = window.localStorage.getItem('seen');
  if (!seen) {
    seen = [];
  } else {
    seen = JSON.parse(seen);
  }

  if (!seen.includes(file)) {
    seen.push(file);
    window.localStorage.setItem('seen', JSON.stringify(seen));
  }
}

export function getCommandCompletion() {
  return JSON.parse(window.localStorage.getItem('completion') || '[]').filter(l => l).length;
}

export function getFilesSeen() {
  return JSON.parse(window.localStorage.getItem('seen') || '[]').length;
}

export function getCompletionAmount() {
  return (getCommandCompletion() + getFilesSeen() * 0.7) / (completionsAmount + fileCount * 0.7);
}