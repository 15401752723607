import { setError } from '.';
import { text, html, escapeHTML } from '../util';
import { getFromPath, dir } from '../fs';
import { setComplete } from '../completion';
import { triggerThreePeaksSequence } from '../threepeaks';
import { renderProjects } from '../projects';

const badges = `
<div class="badges">
  <img src="badges/oatzone.gif" alt="oat.zone" width="88" height="31" title="feel free to use this badge!">
  <img src="badges/oatzonealt.png" alt="oat.zone" width="88" height="31" title="feel free to use this badge!">
  <a href="https://noti.tg/" target="_blank" rel="noopener"><img src="badges/notitg.png" width="88" height="31" alt="notitg" title="play notitg!"></a>
  <img src="badges/robot.png" alt="made by a robot" width="88" height="31" title="beep :)">
  <img src="badges/animegay.gif" alt="anime is gay as hell but i approve!" width="88" height="31">
  <a href="https://maia.crimew.gay/" target="_blank" rel="noopener"><img src="badges/maia.png" width="88" height="31" alt="maia.crimew.gay" title="maia dot crimew dot gay"></a>
  <a href="https://cyber.dabamos.de/88x31" target="_blank" rel="noopener"><img src="badges/88x31.gif" alt="88x31" title="badges (some content may be nsfw!)" width="88" height="31"></a>
  <img src="badges/neo-fedi.gif" alt="join the fediverse!" width="88" height="31">
  <img src="badges/acab.gif" alt="acab" width="88" height="31">
  <img src="badges/notepad.gif" alt="site made with notepad" width="88" height="31">
  <img src="badges/anythingbut.gif" alt="anything but chrome" width="88" height="31">
  <img src="badges/autismnow.png" alt="autism now!" width="88" height="31" title="tbh">
  <img src="badges/sex.gif" width="88" height="31">
  <img src="badges/stw-ziplashblows.png" alt="chibi robo ziplash blows!" width="88" height="31">
  <a href="/800x600.png" target="_blank"><img src="badges/800x600.gif" alt="best viewed at 800x600!" width="88" height="31"></a>
  <img src="badges/buttcertificate.gif" alt="butt certificate" width="88" height="31">
  <a href="https://temptationstairway.neocities.org/" target="_blank"><img src="badges/enabutton.png" alt="ena info" width="88" height="31"></a>
  <img src="badges/slimesnow.png" alt="slimes... now!" width="88" height="31">
  <img src="badges/slugcat.png" alt="Slug ca" title="Slug ca" width="88" height="31">
  <a href="https://versary.town/" target="_blank" rel="noopener"><img src="badges/versarytown.png" width="88" height="31"></a>
  <img src="badges/krisbtn.png" alt="kris where tf are we" width="88" height="31">
  <a href="https://motan.gay/" target="_blank" rel="noopener"><img src="badges/motan.png" alt="motan dot gay" width="88" height="31"></a>
  <a href="https://sinewave.cyou/" target="_blank" rel="noopener"><img src="badges/sinewave.gif" alt="sinewave" width="88" height="31"></a>
  <a href="https://dark-firepit.cloud/" target="_blank" rel="noopener"><img src="badges/dark-firepit.png" alt="dark-firepit" width="88" height="31"></a>
  <img src="badges/bob.gif" alt="powered by bob" width="88" height="31">
  <iframe src="//incr.easrng.net/badge?key=oat_zone" style="background: url(//incr.easrng.net/bg.gif)" title="increment badge" width="88" height="31" frameborder="0"></iframe>
  <a href="https://sudospective.net/" target="_blank" rel="noopener" title="subo!!"><img src="badges/subo.png" alt="subo!!" width="88" height="31"></a>
  <a href="https://suricrasia.online/" target="_blank" rel="noopener"><img src="badges/suricrasia.gif" width="88" height="31"></a>
  <a href="https://mayf.pink/" target="_blank" rel="noopener"><img src="badges/mayf.gif" width="88" height="31"></a>
  <a href="https://corru.observer/" target="_blank" rel="noopener"><img src="badges/corruobserver.gif" width="88" height="31"></a>
  <a href="https://jecket.xyz/" target="_blank" rel="noopener"><img src="badges/jecket.gif" width="88" height="31"></a>
  <img src="badges/equality-switch.gif" alt="i support keymash equality!" width="88" height="31">
  <a href="https://meow.garden/" target="_blank" rel="noopener"><img src="badges/meowgarden.gif" width="88" height="31"></a>
  <img src="badges/rpg2k3.png" alt="shoutouts to ynfgs" width="88" height="31">
  <img src="badges/tidalwave.gif" width="88" height="31">
  <a href="https://kokoscript.com/" target="_blank" rel="noopener"><img src="badges/kokoscript.gif" width="88" height="31"></a>
</div>
`;

export default (args) => {
  if (args.includes('--help')) {
    return text(`Usage: ls [OPTION]... [FILE]...
List information about the FILEs (the current directory by default).

Mandatory arguments to long options are mandatory for short options too.
-a, --all                  do not ignore entries starting with .
-A, --almost-all           do not list implied . and ..`);
  } else {
    let all = args.includes('-a');
    if (all) args = args.filter(i => i !== '-a');
    let almostAll = args.includes('-A');
    if (almostAll) args = args.filter(i => i !== '-A');

    // eslint-disable-next-line no-unused-vars
    let [type, fullPath, files] = getFromPath(args[0] || dir);
    if (!files) {
      setError(true);
      return text('ls: cannot access ' + args[0] + ': No such directory');
    } else if (type === 'file') {
      setError(true);
      return text('ls: cannot read file ' + args[0] + ': Not a directory');
    } else {
      if (fullPath.includes('threepeaks')) {
        triggerThreePeaksSequence();
        return text('');
      }

      if (fullPath === '/home/oatmealine/badges') {
        setComplete(10);
        return html(badges);
      } else if (fullPath === '/home/oatmealine/projects') {
        return renderProjects();
      } else {
        let f = files.map(f => [f, ...getFromPath(dir + '/' + f)]);
        let list = f.map(f => (f[1] === 'directory') ? (f[0] + '/') : f[0]);
        if (all) {
          list = ['.', '..', ...list];
        }
        if (!(all || almostAll)) list = list.filter(f => !f.startsWith('.'));
        return html(
          list
            .map(l => ({html: escapeHTML(l), text: l}))
            .map(l => `<a class="silentlink" onclick="document.querySelector('#commandinput').value = '${l.text.endsWith('/') || l.text.endsWith('.') ? 'cd' : 'cat'} ${l.text}'">${l.html}</a>`)
            .join('<br>')
        );
      }
    }
  }
};
