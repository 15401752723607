import { html } from './util';

let lastCommand;

export function triggerThreePeaksSequence() {
  const input = document.querySelector('#commandinput');
  lastCommand = input.value;
}

const colors = ['black', 'magenta', 'white'];

function randomizeCommand() {
  const htmlText = `
    ${lastCommand.replace(/\S/g, () => `<span class="${Math.random() > 0.5 ? 'bright-' : ''}${colors[Math.floor(Math.random() * colors.length)]}-bg" style="white-space: pre;"> </span>`)}
  `;

  const block = document.querySelector('#threepeaks-sequence');

  if (block.nodeName !== 'DIV') {
    let newBlock = html(htmlText);
    newBlock.id = 'threepeaks-sequence';
    block.replaceWith(newBlock);
  } else {
    block.innerHTML = htmlText;
  }
}

function triggerShake() {
  const body = document.querySelector('body');

  body.style['overflow-x'] = 'hidden';

  body.animate([
    { transform: 'translateX(-4px)', filter: 'saturate(250%)'},
    { transform: 'translateX(4px)',  filter: 'saturate(80%)'},
    { transform: 'translateX(-4px)', filter: 'saturate(250%)'},
    { transform: 'translateX(4px)',  filter: 'saturate(80%)'},
    { transform: 'translateX(-4px)', filter: 'saturate(250%)'},
    { transform: 'translateX(4px)',  filter: 'saturate(80%)'},
    { transform: 'translateX(-4px)', filter: 'saturate(250%)'},
    { transform: 'translateX(4px)',  filter: 'saturate(80%)'},
    { transform: 'translateX(-3px)', filter: 'saturate(200%)'},
    { transform: 'translateX(3px)',  filter: 'saturate(80%)'},
    { transform: 'translateX(-3px)', filter: 'saturate(200%)'},
    { transform: 'translateX(3px)',  filter: 'saturate(80%)'},
    { transform: 'translateX(-2px)', filter: 'saturate(150%)'},
    { transform: 'translateX(2px)',  filter: 'saturate(90%)'},
    { transform: 'translateX(-2px)', filter: 'saturate(150%)'},
    { transform: 'translateX(2px)',  filter: 'saturate(90%)'},
    { transform: 'translateX(-1px)', filter: 'saturate(120%)'},
    { transform: 'translateX(1px)',  filter: 'saturate(90%)'},
    { transform: 'translateX(-1px)', filter: 'saturate(120%)'},
    { transform: 'translateX(1px)',  filter: 'saturate(90%)'},
    { transform: 'translateX(0px)',  filter: 'saturate(100%)'}
  ], {
    duration: 1000,
    easing: 'steps(44, start)',
    fill: 'forwards',
    iterations: 1
  });
}

function fade() {
  const body = document.querySelector('body');
  body.style['overflow-y'] = 'hidden';

  body.animate([
    { transform: 'translateY(0px)', filter: 'brightness(100%)', 'background': '#17101a' },
    { transform: 'translateY(40px)', filter: 'brightness(0%) blur(3px)', 'background': '#000' }
  ], {
    duration: 1000,
    easing: 'cubic-bezier(.66,0,.88,.37)',
    iterations: 1,
    fill: 'forwards'
  }).finished.then(() => {
    window.location = '/three-peaks/';
  });
}

export function onFinishCommand() {
  if (!lastCommand) return;

  const body = document.querySelector('body');
  body.style['pointer-events'] = 'none';

  const block = Array.from(document.querySelectorAll('input[disabled]')).pop();
  document.querySelector('#commandline').remove();

  block.id = 'threepeaks-sequence';

  setInterval(randomizeCommand, 60);
  randomizeCommand();

  triggerShake();

  setTimeout(fade, 1000);
}