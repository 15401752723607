import '@fontsource/libre-franklin';
import { html } from '../util';

let ip;

// set up event listener for keydown and mousemove
let currentVideo = 'left';
let timeout = null;

// function to toggle video visibility and controls
function toggleVideoVisibility(videoId) {
  let video = document.getElementById(videoId);
  video.style.display = 'block';
  video.currentTime = 0;
  video.controls = false;
  video.playbackRate = 3;
  video.play();
  let otherVideoId = (videoId === 'left') ? 'right' : 'left';
  let otherVideo = document.getElementById(otherVideoId);
  otherVideo.style.display = 'none';
  otherVideo.pause();
}

// function to set the idle timer
function setTimer() {
  clearTimeout(timeout);
  timeout = setTimeout(function() {
    toggleVideoVisibility(currentVideo, false);
    currentVideo = 'idle';
    toggleVideoVisibility(currentVideo, false);
  }, 2000);
}

let added = false;

export default () => {
  if (added) return;
  added = true;

  document.addEventListener('keydown', function() {
    clearTimeout(timeout);
    toggleVideoVisibility(currentVideo, false);
    currentVideo = (currentVideo === 'left') ? 'right' : 'left';
    toggleVideoVisibility(currentVideo, true);
    setTimer();
  });
  document.addEventListener('mousemove', function() {
    setTimer();
  });
  
  return html(`
    <div style="background-image:url('/tucker.png');background-size:100% 100%;width:500px;height:369px;position:relative;">
      <span id="ipAddressEpicSwap" style="position:absolute;color:white;width: 200px;left: 29%;top:10%; white-space: nowrap; overflow: hidden; transform: scaleX(1.3) scaleY(4);font-family: 'Libre Franklin', sans-serif;font-weight: 900">${ip || '127.0.0.1'}</span>
      <div style="position:absolute;left:18%;top:36.5%;transform:scale(1.5,1.5);width:320px;height:180px;">
        <video style="top:0;left:0;position:absolute;" id="idle" src="/idle.mp4" playsinline loop></video>
        <video style="top:0;left:0;position:absolute;" id="left" src="/left.mp4" playsinline style="display:none;"></video>
        <video style="top:0;left:0;position:absolute;" id="right" src="/right.mp4" playsinline style="display:none;"></video>
      </div>
    </div>
  `);
};

fetch('https://oat.zone/ip.php')
  .then(response => response.text())
  .then(res => ip = res)
  .catch(error => console.error(error));
