import { setComplete } from '../completion';
import { html } from '../util';

const sl = [
  'UYYzhjUzCOU',
  'P3F20t6PoYQ',
  '2uED1kz9Xsg',
  'L7mxA-dfwa4',
  'e1XgPgcAZiw',
];

export default () => {
  setComplete(11);
  let src = sl[Math.floor(Math.random() * sl.length)];
  return html(`<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${src}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`);
};