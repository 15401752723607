import { setError } from '.';
import { getFromPath } from '../fs';
import { text, html } from '../util';
import { markFileAsSeen } from '../completion';
import { triggerThreePeaksSequence } from '../threepeaks';

export default (args) => {
  if (args.includes('--help')) {
    return text(`Usage: cat [OPTION]... [FILE]...
Concatenate FILE(s) to standard output.`);
  } else if (!args[0]) {
    setError(true);
    return text('cat: missing file operand');
  } else {
    let [t, r, p] = getFromPath(args[0]);
    if (t === 'directory') {
      setError(true);
      return text(`cat: ${args[0]}: Is a directory`);
    } else if (t === 'none') {
      setError(true);
      return text(`cat: ${args[0]}: No such file or directory`);
    } else {
      if (p.includes('threepeaks')) {
        triggerThreePeaksSequence();
        return text('');
      }

      markFileAsSeen(p);
      if (r.startsWith('https://')) {
        window.open(r, '_newtab');
        return html(`<b class="black">redirecting you to <a href="${r}">${r}</a></b>`);
      } else if (r.startsWith('<')) {
        return html(r);
      } else {
        return text(r);
      }
    }
  }
};