export let commandHistory = [];

export function pushHistory(command) {
  const historyNode = document.querySelector('#commandline').cloneNode(true);
  historyNode.id = '';
  document.querySelector('#history').appendChild(historyNode);
  const historyInput = document.querySelector('#commandinput');
  historyInput.disabled = true;
  if (command) historyInput.value = command;
  historyInput.id = '';
  document.querySelector('#commandinput').value = '';
}

export function pushHistoryRaw(command) {
  commandHistory.push(command);
}

export function setCommandHistory(h) {
  commandHistory = h;
}