import commands from './commands/index';
import { text } from './util';

export let error = false;
export function setError(bool) {
  error = bool;
}

export function commandShortcut(command) {
  return `<a class="silentlink" onclick="document.querySelector('#commandinput').value = '${command}'">${command}</a>`;
}

export function executeCommand(line) {
  const command = line.trim().split(' ')[0];
  let args = line.trim().split(' ').slice(1);

  for (const [cmd, func] of Object.entries(commands)) {
    if (cmd === command) {
      return func(args);
    }
  }

  setError(true);
  return text(`qsh: command not found: ${command}`);
}