export let dir = '/home/oatmealine';
let fs = {
  'home': {
    'oatmealine': {
      'girl': 'nya~!',
      'scrunkly.jpg': '<img src="scrunkly.jpg" width="303" height="404">',
      '.hello': 'check out yugoslavia.best',
      'resume.pdf': 'https://oat.zone/resume.pdf',
      'f': {
        '.pisscache.txt': 'https://oat.zone/f/pisscache.txt/',
        '210104_-_how_to_summon_sheball.png': 'https://oat.zone/f/210104_-_how_to_summon_sheball.png',
        'Meal.png': 'https://oat.zone/f/Meal.png',
      },
      'badges': {},
      'projects': {},
      'bnuuy.gif': '<img src="bnuuy.webp" width="150" height="143">',
      'pet.gfp': '<iframe width="314" height="321" scrolling="no" src="https://gifypet.neocities.org/pet/pet.html?name=spronkle&dob=1662649707&gender=f&element=Air&pet=robot.gif&map=night.gif&background=1995.png&tablecolor=%233d3846&textcolor=black" frameborder="0"></iframe>',
      'characters': {
        'she.chr': `<div class="character-sheet">
<div class="character-art">
  <img src="/sheref.gif" alt="She" width="160" height="320">
  <i>art by <a href="https://twitter.com/TaroNuke" target="_blank">taronuke</a></i>
</div>
<div class="character-desc">
  <b>She</b> <b class="black">(they/them, any/all)</b><br>
  <br>
  <b>height:</b> 197cm<br>
  <b>birthday:</b> 19██, October 19th <b class="black">(19 years old)</b><br>
  <b>gender:</b> <i>"what"</i><br>
  <b>sexuality:</b> pansexual<br>
  <b>alignment:</b> chaotic neutral<br>
  <p>She is a human<b class="black" title=";)">*</b> who lives a simple, nomadic life.<br>
  Their main strength comes from the various magic-adjacent things they've learnt over the years, excelling in spatial magic, like telekinesis or walking through walls.
  Their magic however, is very weak and amateur-ish; they can only really do a little a time before they mess something up (which is how they got their glitch-like visual distortions) or get exhausted. They learnt everything on their own through a single book, which they obtained th██████████████r.<br>
  Their form of communication is very odd. They experience dysphoria when using their voice, so they've learnt to send whatever they wish to say telepathically. To the recipient, it's almost as if they're speaking, but it isn't noticable that they really aren't. Their voice differs a lot between people, because it highly depends on how the recipient imagines their voice would sound like.</p>
  <p><a href="/dump-tool" class="silentlink"><b onmouseover="event.target.classList.remove('black'); event.target.classList.add('red'); event.target.innerText = '[DUMP LOG PS_20XX_10_05-5]'" class="black">...?</b></a></p>
  <b>history</b><br>
  <p>She started out as a profile picture - a frame taken from <a href="https://www.youtube.com/watch?v=QVSDHS9ZLZE" target="_blank">a video by pilotredsun</a>. However, after recieving art, they just sort of... manifested into their own character. Ever since then, many of the features they've gained were because of an unintentional telephone game.<br>
  Their most notable appearance is <a href="https://www.youtube.com/watch?v=5fxjVo7KL1k" target="_blank">UMMU</a>.</p>
  <b>references</b><br>
  <p>
    <details>
      <summary>drawn by <a href="https://twitter.com/RedBirdRabbit/" target="_blank" rel="noreferrer noopener">aura</a></summary>
      <img src="/she-1.png" style="display: block; height: auto; max-width: 400px">
    </details>
    <details>
      <summary>drawn by timber</summary>
      <img src="/she-2.png" style="display: block; height: auto; max-width: 400px">
    </details>
    <details>
      <summary>drawn by <a href="https://twitter.com/segaskulll/" target="_blank" rel="noreferrer noopener">segaskulll</a></summary>
      <img src="/she-3.png" style="display: block; height: auto; max-width: 400px">
    </details>
  </p>
</div>
</div>`,
        'jillo.chr': `<div class="character-sheet">
<div class="character-art">
  <img src="/jilloref-2.png" alt="Jillo" width="225" height="257">
  <i>art by <a href="https://twitter.com/akirapink__" target="_blank">akira</a></i>
</div>
<div class="character-desc">
  <b>Jillo</b> <b class="black">(it/its, she/her)</b><br>
  <br>
  <b>height:</b> varies between 130cm and 170cm<br>
  <b>birthday:</b> 20██, September 14th <b class="black">(age unknown)</b><br>
  <b>gender:</b> <i>"girl with an asterisk"</i><br>
  <b>sexuality:</b> lesbian<br>
  <b>written in:</b> haskellES<br>
  <b>alignment:</b> chaotic good<br>
  <p>Jillo is an orange slimegirl with a computer chip embedded in her. Starting out as a lab experiment and abandoned by her former owner, she managed to escape out into the real world and live as usual.
  Despite her form, she's not fully able to control her body, only melting under pressure and exhaustion and not even knowing that she's controlled by the chip.
  If the chip is to be taken out, she'll quickly melt and decompose within a few weeks; her purely-slime form doesn't last very long and barely displays sentience.</p>
  <b>history</b><br>
  <p>Jillo was first created for a roadtrip story, however she's since went on to be featured in <a href="https://yugoslavia.best/c/" target="_blank">████████████</a>. Now, she generally acts as a "sona"/stand-in for oatmealine.</p>
  <b>references</b><br>
  <p>
    <details>
      <summary>drawn by <a href="https://aether.gay/" target="_blank" rel="noreferrer noopener">aether</a></summary>
      <img src="/jillo-1.png" style="display: block; height: auto; max-width: 350px">
    </details>
    <details>
      <summary>drawn by timber</summary>
      <img src="/jillo-2.png" style="display: block; height: auto; max-width: 350px">
    </details>
    <details>
      <summary>drawn by <a href="https://cathode.church/@akirapink" target="_blank" rel="noreferrer noopener">akira</a></summary>
      <img src="/jillo-3.png" style="display: block; height: auto; max-width: 600px">
    </details>
  </p>
</div>
</div>`
      }
    },
    'breakfast-thread-studios': {
      'index.html': 'https://yugoslavia.best/c/',
      '.log-2022-may-12': atob('PGRpdj4KPGIgY2xhc3M9InJlZCI+dGhlIHNhdmUgaXMgZG9pbmcgd2VpcmQgc2hpdC48L2I+IGFsbCBpJ3ZlIGRvbmUgaXMgZWRpdCB0aGUgc2F2ZWZpbGUsIGFuZDxicj4KYWZ0ZXIgYSBmZXcgcmV0cmllcyBpdCwgYm9vdGVkIG1lIHVwIHRvIGEgc2NyZWVuIGkgbmV2ZXIga25ldyBleGlzdGVkPz88YnI+Cjxicj4KaSB0cmllZCBjb250YWN0aW5nIGV2ZXJ5b25lIG9uIHRoZSB0ZWFtIGJ1dCBldmVyeW9uZSBlbHNlIGhhZCBubyBjbHVlIGl0PGJyPgpleGlzdGVkIGVpdGhlci4uLjxicj4KCmkndmUgdHVja2VkIGl0cycgY29kZSBhd2F5IHVuZGVyIHRoZSA8Yj5zZWNyZXQ8L2I+IGNvbW1hbmQsIHNpbmNlIGkgY2FuIG9ubHk8YnI+CmltYWdpbmUgaXQncyBhIHNlY3JldC4gaSdtIG5vdCBzdXJlIHdoYXQgdG8gZG8gd2l0aCBpdCwgYnV0IG1heWJlIHRpbWUgd2lsbDxicj4KdGVsbDxicj4KPGJyPgotb2F0PGJyPgo8L2Rpdj4='),
      '.log-2022-may-16': atob('PGRpdj4KaSdtIGJlaW5nIGdhc2xpdCBpbnRvIHRoaW5pbmcgdGhlIHNjcmVlbiBkb2Vzbid0IGV4aXN0LiBpJ20gbm90IHN1cmU8YnI+CndoeSBvciB3aGF0IHRvIGRvPGJyPgo8YnI+CmkgdGhpbmsgaSdsbCB0cnkgdG8gZGVsZXRlIGl0IGJ1dCBrZWVwIGEgYmFja3VwIG9uIGFuIG9sZCBoYXJkIGRyaXZlLjxicj4KbWF5YmUgaXQnbGwgY29tZSBpbiBoYW5keSBzb21ldGltZTxicj4KPGJyPgotb2F0PGJyPgo8L2Rpdj4=')
    },
    'threepeaks': {}
  }
};

export function getFromAbsolutePath(p) {
  p = p.replace(/\/[^\s/]+\/\.\./, '');
  p = p.replace(/\/\.\//, '');
  p = p.replace(/\/\.$/, '');
  p = p.replace(/\/+/, '/');
  p = p.slice(1);
  let c = fs;
  let path = '';

  if (p.length === 0) {
    path = '/';
  } else {
    for (let v of p.split('/')) {
      c = c[v];
      path = path + '/' + v;
      if (!c) return ['none', null];
    }
  }

  if (typeof c === 'string') {
    return ['file', c, path];
  } else {
    return ['directory', path, Object.keys(c)];
  }
}

export function getFromPath(p) {
  if (p.startsWith('~')) p = '/home/oatmealine' + p.slice(1);
  if (p.startsWith('/')) return getFromAbsolutePath(p);
  return getFromAbsolutePath(dir + '/' + p);
}

export function getFilesInFS(o) {
  if (!o) o = fs;
  let n = 0;
  for (let k of Object.values(o)) {
    if (typeof k === 'string') n++;
    else n += getFilesInFS(k);
  }
  return n;
}

export function setDir(d) {
  dir = d;
}
